<script module lang="ts">
	interface TProps {
		fullHeight?: boolean;
		fullWidth?: boolean;
		children: Snippet;
	}
</script>

<script lang="ts">
	import classNames from 'classnames';
	import type { Snippet } from 'svelte';

	const { fullHeight, fullWidth, children }: TProps = $props();

	const classes = $derived(
		classNames({
			'full-height': fullHeight,
			'full-width': fullWidth,
		}),
	);
</script>

<content class={classes}>
	{@render children()}
</content>

<style lang="scss">
	content {
		display: block;
		margin: 0 auto;
		width: 100%;
		max-width: 30rem;

		&.full-height {
			height: 100%;
		}

		&.full-width {
			width: 100%;
		}
	}
</style>
